:root {
  --primary-color: #183B86;
  --secondary-color: #9ec3dd;
}

/* this styles belong to month picker in cashbackfile */
.PrivatePickersMonth-root {
  border: none;
  background: none;
}
